export default {
  setup: function() {
    document.addEventListener("DOMContentLoaded", function() {
      const menuButton = document.getElementById("menu-button");
      const menuHolder = document.getElementById("menu-holder");
      if (!menuButton || !menuHolder) return;

      menuButton.addEventListener("click", function(e)  {
        e.preventDefault();
        if (menuHolder.style.display === "none" || menuHolder.style.display === "") {
          menuHolder.style.display = "block";
          menuButton.classList.add('active')
        } else {
          menuHolder.style.display = "none";
          menuButton.classList.remove('active')
        }
      });
    });
  }
}
